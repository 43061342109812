<template>
  <b-card-code :title="this.$route.meta.pageTitle">
    <div class="text-center pt-4" v-if="loading">
      <b-spinner label="Cargando..." />
      Cargando...
    </div>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="3">
          <b-form-group label="Tipo de mensaje" label-for="message-type">
            <b-form-select v-model="item.messageType" :options="messageTypes" id="message-type">
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group label="N° Documento Control de Vectores" label-for="name">
            <b-form-input id="name" v-model="item.digesaId" :required="item.messageType === 'S'" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="N Documento" label-for="docId">
            <b-form-input id="docId" v-model="item.docId" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Asunto" label-for="docSubject">
            <b-form-input id="docSubject" v-model="item.docSubject" required />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Remitente" label-for="docSender">
            <b-form-input id="docSender" v-model="item.docSender" required />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Tipo" label-for="type">
            <b-form-select v-model="item.type" :options="config.types" id="type" value-field="id" text-field="label"
              required>
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Datos del Destinatario" label-for="docReceiver">
            <b-form-input id="receiverName" placeholder="Nombre" v-model="item.receiverName" required />

            <b-form-group label="Institución" label-for="receiverInstitution">
              <b-form-select v-model="item.receiverInstitution" :options="getInstitutions()" options-field="none"
                id="receiverInstitution" value-field="id" text-field="label" required>
                <template #first>
                  <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group label="Dirección" label-for="receiverDirection">
              <b-form-select v-model="item.receiverDirection" :options="getDirections()" id="receiverDirection"
                value-field="id" text-field="label" required>
                <template #first>
                  <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group label="Departamento" label-for="receiverDepartment">
              <b-form-select v-model="item.receiverDepartment" :options="getDeparments()" id="receiverDepartment"
                value-field="id" text-field="label" required>
                <template #first>
                  <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Fecha de Documento">
            <flat-pickr v-model="item.docDate" class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d' }" />
          </b-form-group>

        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Documento">
            <b-form-file v-model="item.document" placeholder="Selecciona un documento"
              drop-placeholder="Coloca el documento aquí" required />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1">
            <span v-if="!loading">Guardar</span>
            <span v-else="loading">Un momento...</span>
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" to="documents">
            Cancelar
          </b-button>
        </b-col>

        <p>{{ errorMessage }}</p>
      </b-row>

    </b-form>
  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BForm, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner, BFormFile, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import environment from "@/environment";
import flatPickr from "vue-flatpickr-component";


export default {
  name: 'DocumentAdd',
  components: {
    BAvatar,
    BBadge,
    BCardCode,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BFormFile,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      messageTypes: [{
        text: "Entrante",
        value: 'E'
      }, {
        text: "Saliente",
        value: 'S'
      }],
      config: {
        organizations: [],
        types: [],
      },
      file: null,
      loading: false,
      item: {
        receiverInstitution: { directions: [] },
        receiverDirection: { deparments: [] },
        type: 'internal',
        blocked: true,
        messageType: 'S'
      },
      errorMessage: '',
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    getInstitutions() {
      return this.config.organizations.filter(o => o.type === this.item.type) || []
    },
    getDirections() {
      return (this.config.organizations.find(o => o.id === this.item.receiverInstitution) || { options: [] }).options
    },
    getDeparments() {
      return (this.getDirections().find(o => o.id === this.item.receiverDirection) || { departments: [] }).departments
    },
    async load() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}documents/config`)
        this.config = resp.data
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async save() {
      try {
        this.loading = true
        this.errorMessage = ''
        const formData = new FormData()

        formData.append('digesaId', this.item.digesaId)
        formData.append('docId', this.item.docId)
        formData.append('docDate', this.item.docDate)
        formData.append('docSubject', this.item.docSubject)
        formData.append('docSender', this.item.docSender)
        formData.append('receiverName', this.item.receiverName)
        formData.append('receiverInstitution', this.item.receiverInstitution)
        formData.append('receiverDirection', this.item.receiverDirection)
        formData.append('receiverDepartment', this.item.receiverDepartment)
        formData.append('type', this.item.type)
        formData.append('messageType', this.item.messageType)
        formData.append('document', this.item.document)

        this.loading = true
        this.errorMessage = ''
        await this.$http.post(`${environment.uri}documents/create`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        this.$router.replace('/documents/documents')
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
